import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import StickyBox from 'react-sticky-box';
import { Fade } from 'react-reveal';

import '../App.css'

const WorksDesigns = (props) => {

    document.title = "Our Work - Designs | Totem Craft Studio"
    window.scrollTo(0, 0)

    const RefBranding = React.createRef()
    function showRefBranding() { window.scrollTo({ top: RefBranding.current.offsetTop + 700, behavior: "smooth" }) }

    const RefLettering = React.createRef()
    function showRefLettering() { window.scrollTo({ top: RefLettering.current.offsetTop + 700, behavior: "smooth" }) }

    // const RefIllustration = React.createRef()
    // function showRefIllustration() { window.scrollTo({ top: RefIllustration.current.offsetTop + 700, behavior: "smooth" }) }

    const RefLogo = React.createRef()
    function showRefLogo() { window.scrollTo({ top: RefLogo.current.offsetTop + 700, behavior: "smooth" }) }

    const RefPoster = React.createRef()
    function showRefPoster() { window.scrollTo({ top: RefPoster.current.offsetTop + 700, behavior: "smooth" }) }

    const RefTshirt = React.createRef()
    function showRefTshirt() { window.scrollTo({ top: RefTshirt.current.offsetTop + 700, behavior: "smooth" }) }


    return (
        <Fragment>
            <header className="light" style={{
                backgroundImage: `url("../BG_animation_atari.jpg")`,
                backgroundSize: 'cover',
                backgroundPosition: 'bottom right',
                height: '100%',
                width: '100%',
            }}>
                <div style={{
                    position: 'absolute',
                    top: 0, bottom: 0, right: 0, left: 0,
                    backgroundColor: `rgba(0,0,0,0.6)`
                }}></div>
                <div className="container" style={{ position: 'relative' }}>
                    <hr className="space-lg" />
                    <hr className="space-lg" />
                    <h1>Our Works - Designs</h1>
                    <h2>Good design is good business</h2>
                    <hr className="space-lg" />
                </div>
            </header>
            <main>
                <section className="section-base">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <StickyBox offsetTop={90} offsetBottom={90} className='menu-inner menu-inner-vertical'>
                                    <ul>
                                        <li>
                                            <Link to="#" onClick={showRefBranding}>Branding</Link>
                                        </li>
                                        <li>
                                            <Link to="#" onClick={showRefLettering}>Lettering</Link>
                                        </li>
                                        {/* <Link to="#" onClick={show}>
                                            <Link to="#" onClick={showRefIllustration}>Illustration</Link>
            </li> */}
                                        <li>
                                            <Link to="#" onClick={showRefLogo}>Logos & Badges</Link>
                                        </li>
                                        <li>
                                            <Link to="#" onClick={showRefPoster}>Posters</Link>
                                        </li>
                                        {/*
                                        <li>
                                            <Link to="#" onClick={showRefTshirt}>T-Shirt Designs</Link>
                                        </li>
        */}
                                    </ul>
                                </StickyBox>
                            </div>
                            <div className="col-lg-9">
                                <div className="title align-center" ref={RefBranding}>
                                    <p><h1>Branding</h1></p>
                                </div>
                                <div className="align-center">
                                    <Fade top cascade>
                                        <img width={'100%'} src={require('../img/designs/branding/01.png')} alt="Branding Designs" />
                                        <hr className="space-sm" />
                                        <img width={'100%'} src={require('../img/designs/branding/02.png')} alt="Branding Designs" />
                                        <hr className="space-sm" />
                                        <img width={'100%'} src={require('../img/designs/branding/03.png')} alt="Branding Designs" />
                                    </Fade>
                                </div>

                                <hr />

                                <div className="title align-center" ref={RefLettering}>
                                    <p><h1>Lettering</h1></p>
                                </div>
                                <div className="align-center">
                                    <Fade top cascade>
                                        <img width={'100%'} src={require('../img/designs/latering.png')} alt="Logo Lettering" />
                                    </Fade>
                                </div>


                                {/*
                                <hr />
                                <div className="title align-center" ref={RefIllustration}>
                                    <p><h1>Illustration</h1></p>
        </div>*/}
                                <div className="align-center">
                                    <Fade top cascade>

                                    </Fade>
                                </div>

                                <hr />

                                <div className="title align-center" ref={RefLogo}>
                                    <p><h1>Logo & Badges</h1></p>
                                </div>
                                <div className="align-center">
                                    <Fade top cascade>
                                        <img width={'100%'} src={require('../img/designs/logo.png')} alt="Logo Designs" />
                                    </Fade>
                                </div>

                                <hr />

                                <div className="title align-center" ref={RefPoster}>
                                    <p><h1>Poster</h1></p>
                                </div>
                                <div className="align-center row">
                                    <Fade top cascade>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <img width={'100%'} src={require('../img/designs/poster/atom.jpg')} alt="Poster Atom" />
                                            <hr className="space-sm" />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <img width={'100%'} src={require('../img/designs/poster/35mm.jpg')} alt="Poster 35mm" />
                                            <hr className="space-sm" />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <img width={'100%'} src={require('../img/designs/poster/BISKUT.jpg')} alt="Poster Biskuit" />
                                            <hr className="space-sm" />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <img width={'100%'} src={require('../img/designs/poster/KEMBARA.jpg')} alt="Poster Kembara" />
                                            <hr className="space-sm" />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <img width={'100%'} src={require('../img/designs/poster/LE.jpg')} alt="Poster Le" />
                                            <hr className="space-sm" />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <img width={'100%'} src={require('../img/designs/poster/rider.jpg')} alt="Poster rider" />
                                            <hr className="space-sm" />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <img width={'100%'} src={require('../img/designs/poster/pada.jpg')} alt="Poster pada" />
                                            <hr className="space-sm" />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <img width={'100%'} src={require('../img/designs/poster/rangup.jpg')} alt="Poster rangup" />
                                            <hr className="space-sm" />
                                        </div>
                                    </Fade>
                                </div>

                                <hr />

                                {/*
                            <div className="title align-center" ref={RefTshirt}>
                                    <p><h1>T-shirt</h1></p>
                                </div>
                                <div className="align-center">
                                    <Fade top cascade>

                                    </Fade>
                                </div>
                            */}
                            </div>
                        </div>
                        <hr className="space-lg" />
                        <div className="cnt-box cnt-call">
                            <i className="im-paper-plane"></i>
                            <div className="caption">
                                <h2>Booking and contacts</h2>
                                <p>
                                    Would you like more info about the services or you want to book?
                                </p>
                                <a href="https://wa.me/60178753767" className="btn btn-xs">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Fragment>
    )
}

export default WorksDesigns;