import React from "react";
// This is the way to import an SVG file and then pass it as a props
import { ReactComponent as Gambar } from "../img/totem.svg";
import { WhatsAppWidget } from "react-whatsapp-widget";
import "./whatsapp.css";

const WhatsApp = () => {
  return (
    <WhatsAppWidget
      companyName="Totem Craft Studio"
      CompanyIcon={Gambar}
      message={`Hello! \n\nWhat can Totem Craft do for you?`}
      phoneNumber="60197364364"
      replyTimeText="Typically replies within an hour"
    />
  );
};

export default WhatsApp;
