import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import StickyBox from 'react-sticky-box';
import { Fade } from 'react-reveal';

import '../App.css'

const WorksWebApps = (props) => {

    document.title = "Our Work - Web & Apps | Totem Craft Studio"
    window.scrollTo(0, 0)

    const RefWebApp = React.createRef()
    function showRefWebApp() { window.scrollTo({ top: RefWebApp.current.offsetTop + 700, behavior: "smooth" }) }


    return (
        <Fragment>
            <header className="light" style={{
                backgroundImage: `url("../BG_Webdev.jpg")`,
                backgroundSize: 'cover',
                height: '100%',
                width: '100%',
            }}>
                <div style={{
                    position: 'absolute',
                    top: 0, bottom: 0, right: 0, left: 0,
                    backgroundColor: `rgba(0,0,0,0.6)`
                }}></div>
                <div className="container" style={{ position: 'relative' }}>
                    <hr className="space-lg" />
                    <hr className="space-lg" />
                    <h1>Our Works - Web & Apps</h1>
                    <h2>Develop the future solutions </h2>
                    <hr className="space-lg" />
                </div>
            </header>
            <main>
                <section className="section-base">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <Fade left cascade>
                                    <StickyBox offsetTop={90} offsetBottom={90} className='menu-inner menu-inner-vertical'>
                                        <ul>
                                            <li>
                                                <Link to="#" onClick={showRefWebApp}>Web Dev</Link>
                                            </li>
                                            <li>
                                                <a href="#">App Dev</a>
                                            </li>
                                        </ul>
                                    </StickyBox>
                                </Fade>
                            </div>
                            <div className="col-lg-9">
                                <div className="align-center" ref={RefWebApp}>
                                    <Fade left cascade>
                                        <a style={{ textDecoration: 'none' }} href="https://ketik.co.id/">
                                            <img width={'100%'} src={require('../img/web_icons/ketik.png')} alt="Ketik" />
                                            <h2><i>Ketik - Media Kolaborasi Indonesia</i></h2>
                                        </a>
                                    </Fade>

                                    <hr className="space-sm" />

                                    <Fade left cascade>
                                        <a style={{ textDecoration: 'none' }} href="https://glocal.com.my/">
                                            <img width={'100%'} src={require('../img/web_icons/glocal.png')} alt="GLocal" />
                                            <h2><i>Glocal Travel - Umrah dan Haji Specialist</i></h2>
                                        </a>
                                    </Fade>

                                    <hr className="space-sm" />

                                    <Fade left cascade>
                                        <a style={{ textDecoration: 'none' }} href="https://kembarautan.com/">
                                            <img width={'100%'} src={require('../img/web_icons/kembarautan.png')} alt="Kembara Utan" />
                                            <h2><i>Kembara Utan</i></h2>
                                        </a>
                                    </Fade>

                                    <hr className="space-sm" />

                                    <Fade left cascade>
                                        <a style={{ textDecoration: 'none' }} href="https://store.lazyntax.com/">
                                            <img width={'100%'} src={require('../img/web_icons/store_lazyntax.png')} alt="Lazytax Store" />
                                            <h2><i>Lazyntax Store</i></h2>
                                        </a>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                        <hr className="space-lg" />
                        <div className="cnt-box cnt-call">
                            <i className="im-paper-plane"></i>
                            <div className="caption">
                                <h2>Booking and contacts</h2>
                                <p>
                                    Would you like more info about the services or you want to book?
                                </p>
                                <a href="https://wa.me/60178753767" className="btn btn-xs">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Fragment>
    )
}

export default WorksWebApps;