import React from 'react'
import Slides from "react-reveal/Slide";


const Clients = () => {

    const tempat_logo = {
        display: 'flex',
        flexWrap: 'wrap',
        flexFlow: 'flex-flow',
        justifyContent: 'center',
        alignItems: 'center',
        // height: '150px',
        width: '100%',
        padding: '4px',
    }

    const sect = {
        // height: '60vh',
        width: '100%',
        padding: '10px',
    }

    const awards = {
        borderRadius: '4px',
        display: 'flex',
        maxHeight: '90px',
        maxWidth: '180px',
        width: 'auto',
        height: '180px',
        margin: 'auto'
    }


    function importAll(r) {
        let images = {};
        // eslint-disable-next-line array-callback-return
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }

    const images = importAll(require.context('../img/awards', false, /\.(png|jpe?g|svg|webp)$/));

    return (
        <main>
            <section class="section-base section-color"
                style={sect}
            >
                <div className='container'>
                    <Slides top cascade>
                        <div className="title align-center">
                            <h2>OUR AWARDS</h2>
                            <p>Totem Craft Studio</p>
                        </div>
                    </Slides>

                    <Slides bottom cascade>
                        <div className="awards" style={tempat_logo}>
                            <img style={awards} src={images['AIM01.png']} alt="awards_logo" />
                            <img style={awards} src={images['AIM02.png']} alt="awards_logo" />
                            <img style={awards} src={images['JTE_01.png']} alt="awards_logo" />
                            <img style={awards} src={images['JTE_02.png']} alt="awards_logo" />
                            <img style={awards} src={images['JTE_03.png']} alt="awards_logo" />
                            <img style={awards} src={images['LE_01.png']} alt="awards_logo" />
                            <img style={awards} src={images['LE_02.png']} alt="awards_logo" />
                            <img style={awards} src={images['LE_03.png']} alt="awards_logo" />
                        </div>
                    </Slides>

                </div>
            </section>
        </ main >
    )
}
export default Clients