import React, { useState } from 'react'
import AboutUs from './AboutUs'
import OurServices from './OurServices'
import OurWorks from './OurWorks'
import Client from './Client'
import Awards from './Awards'
import Slides from "react-reveal/Slide";
import { Fragment } from 'react';
import FsLightbox from 'fslightbox-react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../App.css'



const settings = {
    dots: true,
    autoplay: true,
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
    ]
};

const images = [
    {
        img: require('../img/thumbnail/atom.png'),
        link: 'https://www.youtube.com/watch?v=avO7Q5GaYNc',
        type: 'video'
    },
    {
        img: require('../img/thumbnail/wp_totem.jpg'),
        link: 'https://www.youtube.com/watch?v=mcxCecHc5Wo',
        type: 'video'

    },
    {
        img: require('../img/thumbnail/yongsari.jpg'),
        link: 'https://www.youtube.com/watch?v=I-_NJRckkBk',
        type: 'video'

    },
    {
        img: require('../img/thumbnail/seram_raya.png'),
        link: 'https://www.youtube.com/watch?v=j5RJ7zx5xWg',
        type: 'video'

    },
    {
        img: require('../img/thumbnail/35mm.jpeg'),
        link: 'https://www.youtube.com/watch?v=WgW4RfNRTwY&t=29s',
        type: 'video'

    },
    {
        img: require('../img/thumbnail/zouq.png'),
        link: 'https://www.youtube.com/watch?v=mYjflHJJ4Wc',
        type: 'video'
    },
    {
        img: require('../img/thumbnail/zain.png'),
        link: 'https://www.youtube.com/embed/fDoh1U2c-yw',
        type: 'video'
    },
    {
        img: require('../img/thumbnail/jk.jpg'),
        link: 'https://www.youtube.com/watch?v=49uXR0Y8Ihk',
        type: 'video'

    },
]

const Home = (props) => {

    document.title = "Totem Craft Studio"
    window.scrollTo(0, 0)

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        sourceIndex: 0
    });

    function openLightboxOnSource(sourceIndex) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            sourceIndex: sourceIndex
        });
    }

    return (
        <Fragment >
            <div className="light">
                <div style={{
                    backgroundImage: `url("../BG_home.jpg")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    height: '100%',
                    width: '100%',
                }} >
                    <div className="container">
                        <hr className="space-lg" />
                        <hr className="space-sm" />
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <Slides top cascade>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        height: '100%',
                                    }} >
                                        <h1>TOTEM CRAFT STUDIO (M)</h1>
                                        <h1 >Sdn Bhd</h1>
                                        <hr className="space-sm" />
                                        <h3>"Art is not what you see, <br /> but what you make others see"</h3>
                                        <hr className="space-md" />
                                        <p className="quote">
                                            "I was created to create!"
                                            <span className="quote-author">Indra Putra "SANJI"</span>
                                        </p>
                                    </div>
                                </Slides>

                            </div>
                            <div className="col-lg-6 col-12">
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        height: 'vh',
                                        marginTop: '20px',
                                        padding: '5px',
                                    }} >

                                    <Slides bottom cascade>

                                        <div className="keterangan"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyItems: 'space-around',
                                                justifyContent: 'center',
                                                alignItems: 'space-around',
                                                height: '300px',
                                                padding: '5px',
                                            }} >

                                            <div className="establis"
                                                style={{
                                                    marginBottom: '40px',
                                                }}
                                            >

                                                <h3>Established</h3>
                                                <div className="value "
                                                >
                                                    <span className="text-md" data-to="2012" data-speed="5000">2012</span>
                                                </div>
                                            </div>
                                            <div >
                                                <div>
                                                    <h3>Clients Count</h3>
                                                    <div className="value">
                                                        <span className="text-md" data-to="62" data-speed="5000">62</span>
                                                        <span>+</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slides>
                                    <Slides bottom cascade>
                                        <Slider {...settings}>
                                            {images.map((image, index) => (
                                                <div>
                                                    <span className={`${image.type === 'video' ? 'btn-video' : ''} img-box`} onClick={() => openLightboxOnSource(index)} style={{ paddingTop: 52 }}>
                                                        <img width={'100%'} height={'100%'} src={image.img} alt={`gambar-${image.index}`} style={{ marginTop: -52 }} />
                                                    </span>
                                                </div>
                                            ))}
                                        </Slider>

                                        <FsLightbox
                                            toggler={lightboxController.toggler}
                                            sourceIndex={lightboxController.sourceIndex}
                                            sources={images.map(image => (
                                                image.link
                                            ))}
                                        />
                                    </Slides>

                                </div>
                            </div></div>
                        <hr className="space-lg" />
                    </div>
                </div>
            </div >
            <AboutUs aboutRef={props.aboutRef} />
            <Client />
            <OurServices myRef={props.myRef} />
            <Awards />
            <OurWorks />
        </Fragment >
    )
}

export default Home;