import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import StickyBox from 'react-sticky-box';

import '../App.css'

const WorksAnimations = (props) => {

    document.title = "Our Work - Animations | Totem Craft Studio"
    window.scrollTo(0, 0)

    const Ref3D = React.createRef()
    function showRef3D() { window.scrollTo({ top: Ref3D.current.offsetTop + 700, behavior: "smooth" }) }

    const Ref2D = React.createRef()
    function showRef2D() { window.scrollTo({ top: Ref2D.current.offsetTop + 700, behavior: "smooth" }) }

    return (
        <Fragment>
            <header className="light" style={{
                backgroundImage: `url("../BG_animation.png")`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top top',
                height: '100%',
                width: '100%',
            }}>
                <div style={{
                    position: 'absolute',
                    top: 0, bottom: '0', right: 0, left: 0,
                    backgroundColor: `rgba(0,0,0,0.6)`
                }}></div>
                <div className="container" style={{ position: 'relative' }}>
                    <hr className="space-lg" />
                    <hr className="space-lg" />
                    <h1>Our Works - Animations</h1>
                    <h2>If you can dream it, you can do it</h2>
                    <hr className="space-lg" />
                </div>
            </header>
            <main>
                <section className="section-base">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <StickyBox offsetTop={90} offsetBottom={90} className='menu-inner menu-inner-vertical'>
                                    <ul>
                                        <li>
                                            <Link to="#" onClick={showRef3D}>3D Animation</Link>
                                        </li>
                                        <li>
                                            <Link to="#" onClick={showRef2D}>2D Animation</Link>
                                        </li>
                                    </ul>
                                </StickyBox>
                            </div>
                            <div className="col-lg-9">
                                <div className="title align-center" ref={Ref3D}>
                                    <p><h1>3D Animation</h1></p>
                                </div>
                                <div className="align-center">
                                    <iframe width="100%" height="415" src="https://www.youtube.com/embed/LFzWs6xIIzE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Mat Reka Pilot</i></h2>

                                    <hr className="space-sm" />

                                    <iframe width="100%" height="415" src="https://www.youtube.com/embed/G60xlDreTOo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Atom Kidz</i></h2>

                                    <hr className="space-sm" />

                                    <iframe width="100%" height="415" src="https://www.youtube.com/embed/mYjflHJJ4Wc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Zougqy TVC</i></h2>

                                </div>
                                <hr className="space-md" />
                                <div className="title align-center" ref={Ref2D}>
                                    <p><h1>2D Animation</h1></p>
                                </div>
                                <div className="align-center">
                                    <iframe width="100%" height="415" src="https://www.youtube.com/embed/fDoh1U2c-yw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Zain Bhikha</i></h2>

                                    <hr className="space-sm" />

                                    <iframe width="100%" height="415" src="https://www.youtube.com/embed/xxe0ALF_RDY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Rangup</i></h2>
                                </div>
                            </div>
                        </div>
                        <hr className="space-lg" />
                        <div className="cnt-box cnt-call">
                            <i className="im-paper-plane"></i>
                            <div className="caption">
                                <h2>Booking and contacts</h2>
                                <p>
                                    Would you like more info about the services or you want to book?
                                </p>
                                <a href="https://wa.me/60178753767" className="btn btn-xs">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Fragment>
    )
}

export default WorksAnimations;