import React, { Fragment } from 'react'

import { Fade } from 'react-reveal'

import '../App.css'

const capabilities = [{ img: 'React.webp', title: 'React JS' }, { img: 'Nextjs.png', title: 'Next JS' }, { img: 'Laravel.png', title: 'Laravel' }, { img: 'Android.png', title: 'Android Apps' }, { img: 'xcode.png', title: 'iOS Apps' }, { img: 'codeigniter.svg', title: 'Codeigniter' }
]

const whatyougets = [{ icon: 'im-air-balloon', title: 'The best team', description: 'Your web and apps will be handled by our best team' }, { icon: 'im-bar-chart2', title: 'after sales service', description: 'We will maintain our web and apps so that your web and apps never get outs of date.' }, { icon: 'im-bee', title: 'Best Price', description: "Satisfaction granted" }
]

const WebCapabilities = (props) => {

    return (
        <Fragment>
            <Fade bottom>
                <section className="section-base section-bottom-box">
                    <div className="container align-center">
                        <h1>Our Capabilities</h1>
                        <hr className="space-md" />
                        <div className="row">
                            {capabilities.map((capability, index) => (
                                <Fade key={`capability-${index}`} bottom delay={index * 300}>
                                    <div className="col-lg-4 ">
                                        <div className="cnt-box cnt-box-top-icon boxed align-center">
                                            <hr className="space-sm" />
                                            <img max-width={120} height={100} src={require(`../img/web_icons/${capability.img}`)} alt={capability.img} />
                                            <div className="caption">
                                                <h2 style={{ color: '#2d2d2d' }}>{capability.title}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            ))}
                        </div>
                    </div>
                </section>
            </Fade>
            <Fade top>
                <section className="section-base section-color">
                    <div className="container align-center">
                        <h1>Why You Should Have a Web/Apps?</h1>
                        <hr className="space-md" />
                        <div className="row">
                            <div className="col-lg-8 align-left">
                                <p>
                                    Did you know that the level of customer trust in a company or organization increases if you have a website? Maybe you have thought that you can build a brand from a micro site or social media. But still not optimal. Because micro sites or social media will not be able to represent the brand itself.                            </p>
                                <p>
                                    In addition, having a website will optimize your online promotion. The website will introduce your business to the whole world anytime, anywhere without any limitation of time and place. Websites are like marketing tools that constantly promote, even if you are sleeping. We understand that, and we are here to help grow your business.                                </p>
                            </div>
                            <div className="col-lg-4" style={{ paddingRigh: 0, paddingLeft: 0 }}>
                                <div className="cnt-box cnt-box-side">
                                    <img
                                        style={{
                                            borderRadius: '5px'
                                        }}

                                        src={require('../img/web_icons/laptop.png')} alt="Laptop progressive" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fade>
            <Fade bottom>
                <section className="section-base">
                    <div className="container align-center">
                        <h1>What you get?</h1>
                        <hr className="space-md" />
                        <div className="row">
                            {whatyougets.map((get, index) => (
                                <Fade key={`get-${index}`} bottom delay={index * 300}>
                                    <div className="col-lg-4">
                                        <div className="icon-box icon-box-left">
                                            <i className={`${get.icon}`}></i>
                                            <div className="caption">
                                                <h3>{get.title}</h3>
                                                <p>{get.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            ))}
                        </div>
                    </div>
                </section>
            </Fade>
        </Fragment>
    )
}

export default WebCapabilities;