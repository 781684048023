import React from 'react'

import '../App.css'
import WebCapabilities from './WebCapabilities';
import WebHero from './WebHero';
import WebProcesses from './WebProcesses';

const WebPage = (props) => {

    document.title = "Web & Apps Development | Totem Craft Studio"
    window.scrollTo(0,0)

    return (
        <>
            <WebHero />
            <main>
            <WebCapabilities />
            <WebProcesses/>
            </main>
        </>
    )
}

export default WebPage;