import React from "react";
import { Slide, Fade } from "react-reveal"

const AboutUs = (props) => {

  document.title = "Totem Craft Studio"
  window.scrollTo(0, 0)

  const tempat_cop = {
    display: 'flex',
    flexWrap: 'wrap',
    flexFlow: 'row wrap',
    flexBasis: '50%',
    justifyContent: 'center',
    height: '200px',
    width: 'auto',
  }
  const copTotem = {
    borderRadius: '4px',
    display: 'flex',
    height: '200px',
    width: 'auto',
  }
  const tempat_person = {
    display: 'flex',
    flexWrap: 'wrap',
    flexFlow: 'row wrap',
    flexBasis: '50%',
    justifyContent: 'center',
    height: '200px',
    width: 'auto',
    margin: '5px'
  }
  const foto_person = {
    borderRadius: '4px',
    display: 'flex',
    height: '220px',
    width: 'auto',

  }

  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }

  const images = importAll(require.context('../img', false, /\.(png|jpe?g|svg|webp)$/));
  return (
    <main ref={props.aboutRef}>
      <section className="section-base section">
        <div className="container">
          <Slide top cascade>
            <div className="title align-center">
              <h2>About Us</h2>
              <p>Totem Craft Studio</p>
            </div>
          </Slide>
          <div className="row">
            <div className="col-lg-4">
              <Fade>
                <div className="logo" style={tempat_cop}>
                  <img style={copTotem} src={images["tc.png"]} alt="totemcraft_cop" />
                </div>
              </Fade>
            </div>
            <div className="col-lg-8">
              <Slide bottom>
                <p>
                  Totem Craft Studio are specializing in crative advertising campaigns and
                  products, Our team creates amazing video, 2D/3D animation,
                  designs, develops viral campaigns, commercials, websites, apps &
                  AR.
                </p>
                <br />
                <p>
                  Using our creativity and ideological potential, we offer our
                  clients advertising solutions athat are not only visually
                  beatiful, but also increase the sales. Of course, before taking
                  on any business, we carefully study the client, the product and
                  the market, to provide a stable basis for our ideas.
                </p>
              </Slide>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <Fade>
                <div className="logo" style={tempat_person}>
                  <img style={foto_person} src={images["rafis.png"]} alt="rafis" />
                </div>
              </Fade>
            </div>
            <div className="col-lg-8">
              <Slide bottom>
                <h3 style={{ marginBottom: -3 }}>
                  RAFIS
                </h3>
                <span><i>Director TCS Malaysia</i></span>
                <p style={{ marginTop: 10 }}>
                  Rafis bin Junaida or better known as Rafis, he is 34 years old and one of the managing directors of TotemCraftStudio(M) Sdn Bhd,  is the publisher of YongSari's movie. His interest in the art
                  Born in Johor Bharu, he start as an entrepreneur who now expanded his wings to Kuala Lumpur, now Rafis also add the entertainment industry as one of his business fields,
                  There are several projects currently being worked on by TotemCraftStudio, among which are the YONGSARI film, the Atom Kidz animation series and several other projects
                  file will be executed.
                </p>
              </Slide>
            </div>

          </div>
          <div className="row">
            <div className="col-lg-4">
              <Fade>
                <div className="logo" style={tempat_person}>
                  <img style={foto_person} src={images["sanji.jpg"]} alt="person" />
                </div>
              </Fade>
            </div>
            <div className="col-lg-8">
              <Slide bottom>
                <h3 style={{ marginBottom: -3 }}>
                  INDRA PUTRA "SANJI"
                </h3>
                <span><i>Director TCS Malaysia</i></span>
                <p style={{ marginTop: 10 }}>
                  Indra Putra was born in Selangor, Malaysia, is an Arts & Media Technology Diploma holder from the Malacca Foundation College. Indra began his career as a Director in the animation industry from 2004 to 2010.
                  He then took the step into the film industry in 2011, and has never looked back. He soon began directing music videos for SRC, Sony Music Malaysia, Astro and RTM.
                  In 2014, Indra directed his first telemovie, Man Rider, for TV3, a local broadcaster. To this day, he has directed various programs for local broadcasters and videos for private organizations.
                </p>
              </Slide>
            </div>

          </div>
          <div className="row">
            <div className="col-lg-4">
              <Fade>
                <div className="logo" style={tempat_person}>
                  <img style={foto_person} src={images["ershad.png"]} alt="person" />
                </div>
              </Fade>
            </div>
            <div className="col-lg-8">
              <Slide bottom>
                <h3 style={{ marginBottom: -3 }}>
                  ERSHAD ZULFITRAH HANS DAKO "ECAD"
                </h3>
                <span><i>Director TCS Indonesia</i></span>
                <p style={{ marginTop: 10 }}>
                  Ershad Zulfitrah Hans Dako is born in Sulawesi, Indonesia. He studied at the Universiti Utara Malaysia majoring in Multimedia. Now adding web programming skills, especially MERN stack, he has also worked as an animator in an animation studio in Malaysia under Indra Putra. Since then the good relationship has continued.
                </p>
              </Slide>
            </div>
          </div>

          <hr className="space-md" />
          {/* <div className="row justify-content-center">
            <div style={{ marginTop: 0 }} className="col-lg-3 col-md-3 col-sm-4 col-6 mb-3">
              <div className="align-center">
                <img style={{ borderRadius: 5 }} width={300} src={images["baha.png"]} alt="Azhar" />
                <div className="caption">
                  <h3 style={{ marginTop: 10, marginBottom: -7 }}>AZHAR BAHA</h3>
                  <p style={{ lineHeight: '19px' }}>Studio Manager<br /><i>TCS Malaysia</i></p>
                </div>
              </div>
            </div>
            <div style={{ marginTop: 0 }} className="col-lg-3 col-md-3 col-sm-4 col-6 mb-3">
              <div className="align-center">
                <img style={{ borderRadius: 5 }} width={300} src={images["zai.png"]} alt="Zain" />
                <div className="caption">
                  <h3 style={{ marginTop: 10, marginBottom: -7 }}>ZAIDATUL AZNI</h3>
                  <p style={{ lineHeight: '19px' }}>Managing Director<br /><i>TCS Malaysia</i></p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </main>
  );
};

export default AboutUs;
