import React from 'react'
import Slides from "react-reveal/Slide";


const Clients = () => {

    const tempat_logo = {
        display: 'flex',
        flexWrap: 'wrap',
        flexFlow: 'flex-flow',
        justifyContent: 'center',
        alignItems: 'center',
        height: '300px',
        width: '100%',
        padding: '4px',
    }

    const sect = {
        height: '90vh',
        width: '100%',
        padding: '10px',
    }

    const logo = {
        borderRadius: '4px',
        display: 'flex',
        maxHeight: '50px',
        maxWidth: '100px',
        width: 'auto',
        height: '100px',
        margin: 'auto'
    }
    const logoWide = {
        borderRadius: '4px',
        display: 'flex',
        maxHeight: '50px',
        maxWidth: '160px',
        width: 'auto',
        height: '100px',
        margin: 'auto'
    }


    function importAll(r) {
        let images = {};
        // eslint-disable-next-line array-callback-return
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }

    const images = importAll(require.context('../img/client_logo', false, /\.(png|jpe?g|svg|webp)$/));

    return (
        <main>
            <section class="section-base section-color"
                style={sect}
            >
                <div className='container'>
                    <Slides top cascade>
                        <div className="title align-center">
                            <h2>OUR CLIENTS</h2>
                            <p>Totem Craft Studio</p>
                        </div>
                    </Slides>

                    <Slides bottom cascade>
                        <div className="logo" style={tempat_logo}>
                            <img style={logo} src={images['logo1.png']} alt="client_logo" />
                            <img style={logo} src={images['logo2.png']} alt="client_logo" />
                            <img style={logo} src={images['logo3.png']} alt="client_logo" />
                            <img style={logo} src={images['logo4.png']} alt="client_logo" />
                            <img style={logo} src={images['logo5.png']} alt="client_logo" />
                            <img style={logo} src={images['logo6.png']} alt="client_logo" />
                            <img style={logo} src={images['logo7.png']} alt="client_logo" />
                            <img style={logo} src={images['logo8.png']} alt="client_logo" />
                            <img style={logo} src={images['logo9.png']} alt="client_logo" />
                            <img style={logo} src={images['logo10.png']} alt="client_logo" />
                            <img style={logo} src={images['logo11.png']} alt="client_logo" />
                            <img style={logo} src={images['logo12.png']} alt="client_logo" />
                            <img style={logo} src={images['logo13.png']} alt="client_logo" />
                            <img style={logo} src={images['logo14.png']} alt="client_logo" />
                            <img style={logo} src={images['logo15.png']} alt="client_logo" />
                            <img style={logo} src={images['logo16.png']} alt="client_logo" />
                            <img style={logo} src={images['logo17.png']} alt="client_logo" />
                            <img style={logo} src={images['logo18.png']} alt="client_logo" />
                            <img style={logo} src={images['logo19.png']} alt="client_logo" />
                            <img style={logo} src={images['logo20.png']} alt="client_logo" />
                            <img style={logo} src={images['logo21.png']} alt="client_logo" />
                            <img style={logo} src={images['logo22.png']} alt="client_logo" />
                            <img style={logoWide} src={images['logo23.png']} alt="client_logo" />
                        </div>
                    </Slides>

                </div>
            </section>
        </ main >
    )
}
export default Clients