import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

function ModalAdv() {
  const [showModal, setShowModal] = useState(true);

  const handleClose = () => setShowModal(false);

  return (
    <Modal size="lg" centered show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="text-black">
          Yong Sari Movie (2024) | First Look
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <iframe
          width="100%"
          height="415"
          src="https://www.youtube.com/embed/I-_NJRckkBk?si=PFhf64AWg729yXz_"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </Modal.Body>
    </Modal>
  );
}

export default ModalAdv;
