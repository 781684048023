import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';

import './NavigasiBootstrap.css'

const NavigasiBootstrap = (props) => {
    const navRefMobile = React.useRef(null);
    const navRefCollapse = React.useRef(null);
    const navContainer = React.useRef(null);
    function checkActiveNav() {
        if (navRefMobile.current.classList.contains('active') || navRefMobile.current.classList.contains('show')) {
            navRefMobile.current.classList.remove("active")
            navRefMobile.current.classList.remove("show")
            navRefCollapse.current.classList.add("collapsed")
            navContainer.current.style.height = 0
        }
    }

    function showService() {
        window.scrollTo({
            top: props.myRef.current.offsetTop - 30,
            behavior: "smooth"
        })
    }

    function showAbout() {
        window.scrollTo({
            top: props.aboutRef.current.offsetTop - 30,
            behavior: "smooth"
        })

        checkActiveNav()
    }

    return (

        <Navbar bg="dark" className='navbar-dark' fixed="top" expand="lg">
            <Container>
                <Navbar.Brand>
                    <Link to="/">
                        <img width="40px" className="logo-default scroll-hide" src={require('../../img/ic_totem_white.png')} alt="logo" />
                        <img width="40px" className="logo-retina scroll-hide" src={require('../../img/ic_totem_white.png')} alt="logo" />
                        <img width="40px" className="logo-default scroll-show" src={require('../../img/logo_icon_totem-05.png')} alt="logo" />
                        <img width="40px" className="logo-retina scroll-show" src={require('../../img/logo_icon_totem-05.png')} alt="logo" />
                        <img height={35} width={169} src={require('../../img/totem_text_white.png')} alt="" srcset="" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle ref={navRefCollapse} aria-controls="basic-navbar-nav" />
                <Navbar.Collapse ref={navRefMobile} id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link><Link to="/">Home</Link></Nav.Link>
                        <NavDropdown ref={navRefMobile} title="Our Work" id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={checkActiveNav}><Link to="/videos">Videos</Link></NavDropdown.Item>
                            <NavDropdown.Item onClick={checkActiveNav}><Link to="/animations">Animations</Link></NavDropdown.Item>
                            <NavDropdown.Item onClick={checkActiveNav}><Link to="/designs">Designs</Link></NavDropdown.Item>
                            <NavDropdown.Item onClick={checkActiveNav}><Link to="/webapps">Web & Apps</Link></NavDropdown.Item>
                            <NavDropdown.Item onClick={checkActiveNav}><Link to="/vr">VR</Link></NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown onClick={showService} ref={navRefMobile} title="Our Services" id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={checkActiveNav}><Link to="/">Pre Production</Link></NavDropdown.Item>
                            <NavDropdown.Item onClick={checkActiveNav}><Link to="/">Digital & Multimedia</Link></NavDropdown.Item>
                            <NavDropdown.Item onClick={checkActiveNav}><Link to="/">Creative Designs</Link></NavDropdown.Item>
                            <NavDropdown.Item onClick={checkActiveNav}><Link to="/">Coorperate Branding</Link></NavDropdown.Item>
                            <NavDropdown.Item onClick={checkActiveNav}><Link to="/">Broadcast & Commercial</Link></NavDropdown.Item>
                            <NavDropdown.Item onClick={checkActiveNav}><Link to="/webappdevelopment">Web & Apps Development</Link></NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link onClick={showAbout}><Link to="/">About Us</Link></Nav.Link>
                        <Nav.Link><Link to="/contactus">Contact Us</Link></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavigasiBootstrap