import React, { Fragment } from 'react'

import { Fade } from 'react-reveal'
import '../App.css'

const WebProcesses = (props) => {

    return (
        <Fragment>
            <Fade right>
                <section className="section-base">
                    <div className="container align-center">
                        <h1>How is the Processes?</h1>
                        <hr className="space-md" />
                        <div className="row align-left" style={{ justifyContent: 'center' }}>
                            <div className="col-lg-8">
                                <div className="timeline">
                                    <Fade top delay={300}>
                                        <div>
                                            <div className="badge"><p>1. First</p><span>Order</span></div>
                                            <div className="panel">
                                                <h3 className="timeline-title">Order a Web/Apps</h3>
                                                <p>
                                                    Contact our team to place an order for webs and apps development
                                                </p>
                                            </div>
                                        </div>
                                    </Fade>
                                    <Fade bottom delay={300}>
                                        <div className="inverted">
                                            <div className="badge"><p>2. Next</p><span>Design & Concept</span></div>
                                            <div className="panel">
                                                <h3 className="timeline-title">We would discuss about the concept</h3>
                                                <p>

                                                    Discussion about web or app development based on your needs
                                                </p>
                                            </div>
                                        </div>
                                    </Fade>
                                    <Fade top delay={300}>
                                        <div>
                                            <div className="badge"><p>3. Then</p><span>Development & Testing</span></div>
                                            <div className="panel">
                                                <h3 className="timeline-title">Our team develop the solutions</h3>
                                                <p>
                                                    Just sit and relax, let our team develop a website or app according to the results of our discussion in the previous stage                                                </p>
                                            </div>
                                        </div>
                                    </Fade>
                                    <Fade bottom delay={300}>
                                        <div className="inverted">
                                            <div className="badge"><p>4. Finally</p><span>Your Order Ready to Serve</span></div>
                                            <div className="panel">
                                                <h3 className="timeline-title">We are online!</h3>
                                                <p>

                                                    Tadaaaaa your webs and apps are online                                                </p>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fade>
            <Fade top>
                <hr style={{ backgroundColor: '#000003', marginTop: -2 }} className="space-md" />
                <section className="section-base section-call">
                    <div className="container">
                        <div className="cnt-box cnt-call">
                            <i className="im-paper-plane"></i>
                            <div className="caption">
                                <h2>Booking and contacts</h2>
                                <p>
                                    Would you like more info about the services or you want to book?
                                </p>
                                <a href="https://wa.me/60178753767" className="btn btn-xs">Contact us</a>
                            </div>
                        </div>
                    </div>
                </section>
            </Fade>
        </Fragment>
    )
}

export default WebProcesses;