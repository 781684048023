import React from "react";
import "./Footer.css"
import { ImWhatsapp } from 'react-icons/im'


const Footer = () => (

    <footer class="light">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <h4>TOTEM CRAFT STUDIO</h4>
                    <p>Now is the time to take your company image to the next level and Totem Craft Studio can get you on the road to improve success.</p>

                    <h5 className="mt-4">We are not only productive <br /> We are effective</h5>
                </div>
                <div class="col-lg-4">
                    <h4>Contacts</h4>
                    <ul class="icon-list icon-line">
                        <li>18, Jalan SILC 2/4, 79200 Johor Bharu, Johor, Malaysia</li>
                        <li>(+60) 17-317 2540</li>
                    </ul>
                </div>
                <div class="col-lg-4 ">


                    <div class="icon-links icon-social icon-links-grid social-colors">
                        <a target="_blank" rel="noreferrer" class="facebook" href="https://www.facebook.com/totemcraftstudio"><i class="icon-facebook"></i></a>
                        <a target="_blank" rel="noreferrer" class="twitter" href="https://twitter.com/totemcraft_my" ><i class="icon-twitter"></i></a>
                        <a target="_blank" rel="noreferrer" class="instagram" href="https://www.instagram.com/totemcraftstudio"><i class="icon-instagram"></i></a>
                        <a target="_blank" rel="noreferrer" class="whatsapp" href="https://wa.me/60173172540"><i class="icon`"  style={{ marginTop: -3 }}><ImWhatsapp  /></i></a>
                        <a target="_blank" rel="noreferrer" class="google" href="https://www.youtube.com/channel/UC3TTyOVcUd8qaqZyB5dJJlA"><i class="icon-youtube"></i></a>
                    </div>
                    <hr class="space-sm" />
                    <p>Follow us on the social channels to stay tuned.</p>
                </div>
            </div>
        </div>
        <div class="footer-bar">
            <div class="container">
                <span>© 2022 TOTEM CRAFT STUDIO
                </span>
                <span><a href="/">Contact us</a> | <a href="/">Privacy policy</a></span>
            </div>
        </div>
    </footer >
);

export default Footer;
