import Home from "./components/Home";
import NavigasiBootstrap from "./components/partial/NavigasiBootstrap";
import ScrollButton from "./components/partial/ScrollButton";
import { Routes, Route } from "react-router-dom";
import Footer from "./components/partial/Footer";
import ContactUs from "./components/ContactUs";
import AboutUs from "./components/AboutUs";
import OurServices from "./components/OurServices";
import OurWorks from "./components/OurWorks";
import WebPage from "./components/WebPage";
import React, { Fragment } from "react";
import WorksVideos from "./components/WorksVideos";
import WorksAnimations from "./components/WorksAnimations";
import WorksDesigns from "./components/WorksDesigns";
import WorksWebApps from "./components/WorksWebApps";
import Whatsapp from "./components/WhatsApp";
import Office360 from "./components/Office360";
import WorksVR from "./components/WorksVR";
import ModalAdv from "./components/ModalAdv";

const myRef = React.createRef();
const aboutRef = React.createRef();

function App() {
  return (
    <Fragment>
      <NavigasiBootstrap myRef={myRef} aboutRef={aboutRef} />
      <Routes>
        <Route path="/" element={<Home myRef={myRef} aboutRef={aboutRef} />} />
        <Route path="/ourworks" element={<OurWorks />} />
        <Route path="/ourservices" element={<OurServices />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/webappdevelopment" element={<WebPage />} />
        <Route path="/videos" element={<WorksVideos />} />
        <Route path="/animations" element={<WorksAnimations />} />
        <Route path="/designs" element={<WorksDesigns />} />
        <Route path="/webapps" element={<WorksWebApps />} />
        <Route path="/vr" element={<WorksVR />} />
        <Route path="/360-office" element={<Office360 />} />
      </Routes>
      {/* <ModalAdv /> */}
      <Whatsapp />
      <Footer />
      <ScrollButton />
    </Fragment>
  );
}

export default App;
