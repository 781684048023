import React from 'react'
import Slides from "react-reveal/Slide";


import { CgNotes } from 'react-icons/cg';
import { VscFileMedia } from 'react-icons/vsc';
import { FaRegPaperPlane } from 'react-icons/fa';
import { MdOutlineBrandingWatermark } from 'react-icons/md';
import { GoBroadcast } from 'react-icons/go';
import { FaLaptopCode } from 'react-icons/fa';

const OurServices = (props) => {

    return (
        <main ref={props.myRef}>

            <section className="section-base d-flex flex-wrap">
                <div className="container">
                    <Slides top cascade>
                        <div className="title align-center">
                            <h2>Our Services</h2>
                            <p>We provide the best experience</p>
                        </div>
                    </Slides>
                    <table className="table table-grid table-border table-2-md ">

                        <tbody>
                            <Slides bottom cascade>
                                <tr>
                                    <td>
                                        <div className="icon-box icon-box-top align-center">
                                            <i><CgNotes /></i>
                                            <div className="caption">
                                                <h3>Pre Production</h3>
                                                {/* 
                                               <p>Pre Pro Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quam, voluptates!</p>
                                            */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="icon-box icon-box-top align-center">
                                            <i><VscFileMedia /></i>
                                            <div className="caption">
                                                <h3>Digital Multimedia</h3>
                                                {/* 
                                                <p>Digital Multimedia Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa, quod.</p>
                                            */}

                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="icon-box icon-box-top align-center">
                                            <i ><FaRegPaperPlane /></i>
                                            <div className="caption">
                                                <h3>Creative Design</h3>
                                                {/* 
                                                <p>Creative Design Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
                                            */}

                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="icon-box icon-box-top align-center">
                                            <i><MdOutlineBrandingWatermark /></i>
                                            <div className="caption">
                                                <h3>Corporate Branding</h3>
                                                {/* 
                                               
                                                <p>Corporate Branding Lorem ipsum dolor sit amet consectetur.</p>
                                            */}

                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="icon-box icon-box-top align-center">
                                            <i><GoBroadcast /></i>
                                            <div className="caption">
                                                <h3>Broadcast & Commercial</h3>
                                                {/* 
                                              
                                                <p>Broadcast & Commercial Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facilis.</p>
                                            */}

                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="icon-box icon-box-top align-center">
                                            <i ><FaLaptopCode /></i>
                                            <div className="caption">
                                                <h3>Web & Apps Development</h3>
                                                {/* 
                                               
                                                <p>Web & Apps Development Lorem ipsum dolor sit amet consectetur.</p>
                                            */}

                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </Slides>
                        </tbody>

                    </table>
                </div>
            </section>
        </main >
    )
}

export default OurServices

