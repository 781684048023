import React from 'react'

const Office360 = () => {

    return (
        <main>
            <section className="section-base section-color py-5"
            >
                <div className='container' style={{ paddingBottom: 50 }}>
                  <div className='position-relative'>
                  <iframe title='Office' width="100%" height="640" style={{width: '100%', height: '640px', border: 'none', maxWidth: '100%', position: 'relative'}} allow="xr-spatial-tracking; vr; gyroscope; accelerometer; fullscreen; autoplay; xr" scrolling="no" allowfullscreen="true"  frameborder="0" src="https://webobook.com/public/65b1ca78d70e05585c19de72,en?ap=true&si=true&sm=false&sp=true&sfr=false&sl=false&sop=false&" allowvr="yes" >
                  </iframe>
                  <div className='py-2 bg-black w-100 position-absolute' style={{ bottom: '93px', zIndex:99, }}></div>
                  </div>
                </div>
            </section>
        </ main >
    )
}
export default Office360