import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Container, } from 'react-bootstrap'
import StickyBox from 'react-sticky-box';

import '../App.css'

const WorksVideos = (props) => {

    document.title = "Our Work - Videos | Totem Craft Studio"
    window.scrollTo(0, 0)

    const RefReelVideo = React.createRef()
    function showRefReelVideo() { window.scrollTo({ top: RefReelVideo.current.offsetTop + 700, behavior: "smooth" }) }

    const RefCooperateVideo = React.createRef()
    function showRefCooperateVideo() { window.scrollTo({ top: RefCooperateVideo.current.offsetTop + 700, behavior: "smooth" }) }

    const RefMusicVideo = React.createRef()
    function showRefMusicVideo() { window.scrollTo({ top: RefMusicVideo.current.offsetTop + 700, behavior: "smooth" }) }

    const RefCommercial = React.createRef()
    function showRefCommercial() { window.scrollTo({ top: RefCommercial.current.offsetTop + 700, behavior: "smooth" }) }

    const RefViral = React.createRef()
    function showRefViral() { window.scrollTo({ top: RefViral.current.offsetTop + 700, behavior: "smooth" }) }

    const RefFilem = React.createRef()
    function showRefFilem() { window.scrollTo({ top: RefFilem.current.offsetTop + 700, behavior: "smooth" }) }

    const RefMotion = React.createRef()
    function showRefMotion() { window.scrollTo({ top: RefMotion.current.offsetTop + 700, behavior: "smooth" }) }

    return (
        <Fragment>
            <header className="light" style={{
                backgroundImage: `url("../BG_video.jpg")`,
                backgroundSize: 'cover',
                height: '100%',
                width: '100%',
            }}>
                <div style={{
                    position: 'absolute',
                    top: 0, bottom: 0, right: 0, left: 0,
                    backgroundColor: `rgba(0,0,0,0.6)`
                }}></div>
                <div className="container" style={{ position: 'relative' }}>
                    <hr className="space-lg" />
                    <hr className="space-lg" />
                    <h1>Our Works - Video</h1>
                    <h2>Don't be afraid to get creative and experiment with your marketing</h2>
                    <hr className="space-lg" />
                </div>
            </header>
            <main>
                <section className="section-base">
                    <Container>
                        <Row>
                            <Col lg={3}>
                                <StickyBox offsetTop={90} offsetBottom={90} className='menu-inner menu-inner-vertical'>
                                    <ul>
                                        <li>
                                            <Link to="#id" onClick={showRefReelVideo}>Show Reel</Link>
                                        </li>
                                        <li>
                                            <Link to="#id" onClick={showRefCooperateVideo}>Corporate Video</Link>
                                        </li>
                                        <li>
                                            <Link to="#id" onClick={showRefMusicVideo}>Music Video</Link>
                                        </li>
                                        <li>
                                            <Link to="#id" onClick={showRefCommercial}>Commercial</Link>
                                        </li>
                                        <li>
                                            <Link to="#id" onClick={showRefViral}>Viral Campaign</Link>
                                        </li>
                                        <li>
                                            <Link to="#id" onClick={showRefFilem}>Filem</Link>
                                        </li>
                                        <li>
                                            <Link to="#id" onClick={showRefMotion}>Motion Graphic</Link>
                                        </li>
                                    </ul>
                                </StickyBox>
                            </Col>
                            <Col lg={9}>
                                <div className="title align-center" ref={RefReelVideo}>
                                    <p><h1>Show Reel</h1></p>
                                </div>
                                <div className="align-center">
                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/pTsIxSUtxA4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                                    <hr className="space-sm" />

                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/mcxCecHc5Wo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                                    <hr className="space-sm" />
                                </div>

                                <hr />
                                <hr className="space-md" />

                                <div className="title align-center" ref={RefCooperateVideo}>
                                    <p><h1>Corporate Video</h1></p>
                                </div>
                                <div className="align-center">
                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/_VilPci5YcM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Langkawi</i></h2>

                                    <hr className="space-sm" />

                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/Doxv67EvCPA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Widad Business Group - Tafoni Cenang 2</i></h2>

                                    <hr className="space-sm" />
                                </div>

                                <hr />
                                <hr className="space-md" />

                                <div className="title align-center" ref={RefMusicVideo}>
                                    <p><h1>Music Video</h1></p>
                                </div>
                                <div className="align-center">
                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/SKnzL_esFbk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Klangit - Tak Mau Dusta-dusta</i></h2>

                                    <hr className="space-sm" />

                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/w7zFjmrd1Lk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Hazama - Sampai Mati</i></h2>

                                    <hr className="space-sm" />

                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/wKv-VpuyeH0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Akim & Majistret - Mewangi</i></h2>

                                </div>

                                <hr />
                                <hr className="space-md" />

                                <div className="title align-center" ref={RefCommercial}>
                                    <p><h1>Commercial</h1></p>
                                </div>
                                <div className="align-center">
                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/HB7QuhjJ0KQ?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Mara Corporate Commercial</i></h2>

                                    <hr className="space-sm" />

                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/Km5WTKHUFa4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Don Cafe (Animation- TCS x Tabasco Crotia)</i></h2>

                                    <hr className="space-sm" />

                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/tRqHgXE7mas" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Nara (Animation - TCS x Tabasco Crotia)</i></h2>

                                    <hr className="space-sm" />

                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/JS3M-kwW5BY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Nara (Animation - TCS x Tabasco Crotia)</i></h2>

                                    <hr className="space-sm" />

                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/kiSLtBuP6g0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Simpa (Animation - TCS x Tabasco Crotia)</i></h2>
                                </div>

                                <hr />
                                <hr className="space-md" />

                                <div className="title align-center" ref={RefViral}>
                                    <p><h1>Viral Campaign</h1></p>
                                </div>
                                <div className="align-center">
                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/xR-lx-UIWpw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Bling - bling Karnival Doremi Parody </i></h2>

                                    <hr className="space" />

                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/brC4qOboGMc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>KDU Promo</i></h2>

                                    <hr className="space" />

                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/g2GNiUE9wGQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>TCS - Hantu Ganggu Ketika Belajar</i></h2>

                                    <hr className="space" />

                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/wLZTfD9Z9ww" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>TCS - Seram Raya Sorang 2019</i></h2>

                                    <hr className="space" />

                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/j5RJ7zx5xWg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>TCS - Seram Raya Sorang 2018</i></h2>
                                </div>

                                <hr />
                                <hr className="space-md" />

                                <div className="title align-center" ref={RefFilem}>
                                    <p><h1>Filem</h1></p>
                                </div>
                                <div className="align-center">
                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/I-_NJRckkBk?si=PFhf64AWg729yXz_" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Yong Sari</i></h2>

                                    <hr className="space-sm" />
                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/WgW4RfNRTwY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>35 mm</i></h2>

                                    <hr className="space-sm" />

                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/pYrAqYBDB3U" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>SIKU 12</i></h2>

                                    <hr className="space-sm" />

                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/7SMYyw4N0yE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Last Episode</i></h2>
                                </div>

                                <hr />
                                <hr className="space-md" />

                                <div className="title align-center" ref={RefMotion}>
                                    <p><h1>Motion Graphic</h1></p>
                                </div>
                                <div className="align-center">
                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/ECWgP-d0ZZw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Opening Langit Biru</i></h2>

                                    <hr className="space" />

                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/JDd_6qqJoyI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Opening Ekspresi Muzikku</i></h2>

                                    <hr className="space" />

                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/GAqzR9lUVAE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Alam Flora</i></h2>

                                    <hr className="space" />

                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/yX53Q-QfKyo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Al-Quran Sign and Science</i></h2>

                                    <hr className="space" />

                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/49uXR0Y8Ihk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Opening Jom Kembara Utan</i></h2>

                                    <hr className="space" />

                                    <iframe width={'100%'} height="415px" src="https://www.youtube.com/embed/y6Qc_NBx8vY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2><i>Opening No Gadget No Problem</i></h2>
                                </div>
                            </Col>
                        </Row>
                        <hr className="space-lg" />
                        <div className="cnt-box cnt-call">
                            <i className="im-paper-plane"></i>
                            <div className="caption">
                                <h2>Booking and contacts</h2>
                                <p>
                                    Would you like more info about the services or you want to book?
                                </p>
                                <a href="https://wa.me/60178753767" className="btn btn-xs">Contact Us</a>
                            </div>
                        </div>
                    </Container>
                </section>
            </main>
        </Fragment>
    )
}

export default WorksVideos;