import React, { Fragment } from 'react'

import '../App.css'

const WebHero = (props) => {

    return (
        <Fragment>
            <header className="light" style={{
                    backgroundImage: `url("../BG_Webdev.jpg")`,
                    backgroundSize: 'cover',
                    height: '100%',
                    width: '100%',
                }}>
                <div style={{ 
                    position: 'absolute',
                    top: 0, bottom: 0, right: 0, left: 0,
                    backgroundColor: `rgba(0,0,0,0.6)`
                }}></div>
                <div className="container" style={{  position: 'relative' }}>
                    <hr className="space-lg" />
                    <hr className="space-lg" />
                    <h1>Web & Apps Development</h1>
                    <h2>We are develop your idea into the future solutions.</h2>
                    <hr className="space-lg" />
                </div>
            </header>
        </Fragment>
    )
}

export default WebHero;