import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import StickyBox from 'react-sticky-box';
import { Fade } from 'react-reveal';

import '../App.css'

const WorksVR = (props) => {

    document.title = "Our Work - VR | Totem Craft Studio"
    window.scrollTo(0, 0)

    const RefVRApp = React.createRef()
    function showRefVRApp() { window.scrollTo({ top: RefVRApp.current.offsetTop + 700, behavior: "smooth" }) }


    return (
        <Fragment>
            <header className="light" style={{
                backgroundImage: `url("../BG_animation.jpg")`,
                backgroundSize: 'cover',
                height: '100%',
                width: '100%',
            }}>
                <div style={{
                    position: 'absolute',
                    top: 0, bottom: 0, right: 0, left: 0,
                    backgroundColor: `rgba(0,0,0,0.6)`
                }}></div>
                <div className="container" style={{ position: 'relative' }}>
                    <hr className="space-lg" />
                    <hr className="space-lg" />
                    <h1>Our Works - VR</h1>
                    <h2>Reality is about how you feel it </h2>
                    <hr className="space-lg" />
                </div>
            </header>
            <main>
                <section className="section-base">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <Fade left cascade>
                                    <StickyBox offsetTop={90} offsetBottom={90} className='menu-inner menu-inner-vertical'>
                                        <ul>
                                            <li>
                                                <Link to="#" onClick={showRefVRApp}>VR</Link>
                                            </li>
                                        </ul>
                                    </StickyBox>
                                </Fade>
                            </div>
                            <div className="col-lg-9">
                                <div className="align-center" ref={RefVRApp}>
                                    <Fade left cascade>
                                      <div className='position-relative'>
                                        <iframe title='Office' width="100%" height="640" style={{width: '100%', height: '640px', border: 'none', maxWidth: '100%', position: 'relative'}} allow="xr-spatial-tracking; vr; gyroscope; accelerometer; fullscreen; autoplay; xr" scrolling="no" allowfullscreen="true"  frameborder="0" src="https://webobook.com/public/65b1ca78d70e05585c19de72,en?ap=true&si=true&sm=false&sp=true&sfr=false&sl=false&sop=false&" allowvr="yes" >
                                        </iframe>
                                        <div className='py-2 bg-black w-100 position-absolute' style={{ bottom: '93px', zIndex:99, }}></div>
                                      </div>
                                      <h2 className='mt-2'><i>VR Room</i></h2>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                        <hr className="space-lg" />
                        <div className="cnt-box cnt-call">
                            <i className="im-paper-plane"></i>
                            <div className="caption">
                                <h2>Booking and contacts</h2>
                                <p>
                                    Would you like more info about the services or you want to book?
                                </p>
                                <a href="https://wa.me/60178753767" className="btn btn-xs">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Fragment>
    )
}

export default WorksVR;