import React from 'react'
import Slides from 'react-reveal/Slide'
import { ImWhatsapp } from 'react-icons/im'
import { Button } from 'react-bootstrap'


const ContactUs = () => {


    const tempat_contact = {
        display: 'flex',
        flexWrap: 'wrap',
        flexFlow: 'row wrap',
        flexBasis: '50%',
        justifyContent: 'center',
        width: 'auto',
    }
    return (
        <main>
            <section class="section-base section-color py-5"
            >
                <div className='container' style={{ paddingBottom: 50 }}>
                    <Slides top cascade>
                        <div className="title align-center">
                            <h2>Contact Our Amazing Team</h2>
                            <p>Totem Craft Studio</p>
                        </div>
                    </Slides>

                    <Slides bottom cascade>
                        <div className="logo" style={tempat_contact}

                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <ImWhatsapp style={{ width: '3rem', height: '3rem' }} />
                                <h3 className='mt-3'>Whatsapp</h3>
                                <div className='align-center'>
                                    <br />
                                    <div className='d-block'>
                                        <h3 className='d-inline-block'>(+60) 17-317 2540</h3>
                                        <Button variant='primary' style={{ marginLeft: 15 }} href='https://wa.me/60173172540' target='_blank'>Contact Me</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slides>
                </div>
                <div className="container pt-0 px-lg-5 text-center">
                    <img src={"../office_baru_cropped.png"} alt='Kantor Totemcraft Studio Malaysia' className='img-fluid rounded' width="80%"/><br/>
                    <iframe className='mt-4 rounded' title="Maps Totem Craft Studio Malaysia" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.496468630647!2d103.59039387537001!3d1.4749062985112402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da0b97ca4e4dc3%3A0x9cef0ac885bfc23!2sTotem%20Craft%20Studio!5e0!3m2!1sid!2sid!4v1687143897543!5m2!1sid!2sid" width="80%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe><br/>
                    <h5 className="text-center mt-2">18, Jalan SILC 2/4, 79200 Johor Bharu, Johor, Malaysia</h5>
                </div>
            </section>
        </ main >
    )
}
export default ContactUs