import React from 'react'
import Slides from "react-reveal/Slide";




const OurWorks = () => {

    return (
        <main>
            <section className="section-base section">
                <div className="container">
                    <Slides top cascade>
                        <div className="title align-center">
                            <h2>OUR WORKS</h2>
                            <p>To serve with love</p>
                        </div>
                    </Slides>

                    <div className="row">

                        <div className="col-lg-12 no-margin-md">
                            <Slides bottom>
                                <p>
                                    Design is the ability to see ordinary things in a new extraordinary way. Our Core skills focus on creative, digital and video and in particular areas where they meet.
                                    We blend creativity with a sincere collaborative approach, consistently delivering powerful results for our clients. We work with you to create compelling brands and bespoke digital or printed communications. By getting to know your brand we are able to work with you to design creative and successful interactions across multiple touchpoints.                                </p>
                            </Slides>
                            <hr className="space-sm" />
                        </div>
                    </div>
                    <Slides top cascade>
                        <div className="row">
                            <div className="col-lg-6"
                                style={{ marginBottom: '24px' }} >
                                <ul className="text-list text-list-image">
                                    <li>
                                        <img src={require('../img/web_icons/v1.png')} alt="" />
                                        <div className="content">
                                            <h3>Video</h3>
                                            <p>Corporate Video, Music Video, Commercial, Broadcast, Viral Campaigns, Film, Motion Grafic</p>

                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6"
                                style={{ marginBottom: '24px' }} >
                                <ul className="text-list text-list-image">
                                    <li>
                                        <img src={require('../img/web_icons/v2.png')} alt="" />
                                        <div className="content">
                                            <h3>Animation</h3>
                                            <p>2D & 3D Animation</p>

                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6"
                                style={{ marginBottom: '24px' }} >
                                <ul className="text-list text-list-image">
                                    <li>
                                        <img src={require('../img/web_icons/v3.png')} alt="" />
                                        <div className="content">
                                            <h3>Design</h3>
                                            <p>Branding, Lettering, Illustration, Logo, Poster, Thsirt</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6"
                                style={{ marginBottom: '24px' }} >
                                <ul className="text-list text-list-image">
                                    <li>
                                        <img src={require('../img/web_icons/v4.png')} alt="" />
                                        <div className="content">
                                            <h3>Web & App</h3>
                                            <p>Landing Page,Company Profile, Web Applications, Mobile Applications, E-Commerce, etc </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div >
                    </Slides>
                </div >

            </section >
        </main >
    )
}

export default OurWorks

